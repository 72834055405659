import React from 'react'
import {request, WithContext} from 'reactstrap-toolbox'

class ErrorSummary extends React.Component {
  state = {}

  async componentDidMount () {
    try {
      const r = await request('GET', `/api/import-error/${this.props.match.params.key}/`)
      this.setState({errors: r.data.errors})
    } catch (e) {
      this.props.ctx.setError(e)
    }
  }

  render () {
    return (
      <div className="container box mt-3">
        <pre>Errors: {JSON.stringify(this.state.errors, null, 2)}</pre>
      </div>
    )
  }
}

export default WithContext(ErrorSummary)
