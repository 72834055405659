import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Button, ButtonGroup} from 'reactstrap'
import {withRouter} from 'react-router-dom'
import {as_title, confirm_modal, WithContext} from 'reactstrap-toolbox'

const UNKNOWN = 'Choose a field'

const If = ({check, className, children, icon = 'times'}) => (
  check ? <div className={className || 'error'}><FontAwesomeIcon icon={icon}/> {children}</div> : null
)

export function find_duplicates (arr) {
  const sorted_arr = arr.slice().sort()
  const duplicates = []
  for (let i = 0; i < sorted_arr.length - 1; i++) {
    if (sorted_arr[i + 1] === sorted_arr[i]) {
      duplicates.push(sorted_arr[i])
    }
  }
  return duplicates
}

const count = (arr, value) => arr.reduce((t, x) => x === value ? t + 1: t, 0)

const check_cancel = async (e, history, changed) => {
  if (!changed) {
    history.push('/')
    return
  }
  const message = (
    <div>
      <p>Are you sure you want to go back and start again?</p>
      <p className="font-weight-bold">All the changes made you've made here will be lost</p>
    </div>
  )
  if (await confirm_modal({message, continue_color: 'danger', continue_text: 'Cancel Upload', cancel_text: 'Close'})) {
    history.push('/')
  }
}

const check_reset = async (e, reset) => {
  const message = (
    <div>
      <p>Are you sure you want to reset this sheet to its original state?</p>
      <p className="font-weight-bold">All the changes made you've made here will be lost</p>
    </div>
  )
  if (await confirm_modal({message, continue_color: 'danger', continue_text: 'Reset Sheet', cancel_text: 'Close'})) {
    reset()
  }
}

const Summary_ = ({row_data, column_defs, required_fields, role_type, upload, reset, changed, history, ctx,
                    existing_rows}) => {
  let errors = []
  for (let row of row_data) {
    for (let v of Object.values(row)) {
      if (v.error) {
        errors.push(v.error)
      }
    }
  }
  const unknown = column_defs.filter(c => c.headerName === UNKNOWN).length
  const column_names = new Set(column_defs.map(c => c.headerName))

  const missing_columns = required_fields.filter(f => !column_names.has(f))

  const duplicates = find_duplicates(column_defs.filter(c => c.headerName !== UNKNOWN).map(c => c.headerName))
  const ok = !errors.length && !unknown && !duplicates.length && !missing_columns.length
  const existing_user_count = Object.keys(existing_rows).length
  return (
    <div className="px-2 pb-2 d-flex justify-content-between sheet-summary">
      <div className={ok ? 'success' : 'errors'}>
        <If check={errors.length}>
          {errors.length} errors, please fix all red cells below before importing users:
          <ul className="mb-0">
            {[...(new Set(errors))].map((e, i) => {
              const c = count(errors, e)
              return <li key={i}>{e} ({c === 1 ? 'once' : `${c} times`})</li>
            })}
          </ul>
        </If>
        <If check={unknown}>
          There are {unknown} column{unknown === 1 ? '' : 's'} are not matched to a field name. Choose one, or click
          'Delete column'.
        </If>
        <If check={duplicates.length}>
          The following fields are duplicated: {duplicates.map(c => `"${as_title(c)}"`).join(', ')}.
          Please remove or change some columns.
        </If>
        <If check={missing_columns.length}>
          The following columns are missing but required:&nbsp;
          {missing_columns.map(c => `"${as_title(c)}"`).join(', ')}.
        </If>
        <If check={existing_user_count} className="text-info" icon="user">
          {existing_user_count} user{existing_user_count === 1 ? '' : 's'} already exist and will be updated.
        </If>
        <If check={ok} className="ok" icon="check">
          Everything looks good! Ready for import.
        </If>
      </div>
      <div>
        <ButtonGroup>
          <Button onClick={e => check_cancel(e, history, changed)}>Back</Button>
          {changed ? <Button color="danger" onClick={e => check_reset(e, reset)}>Reset Sheet</Button> : null}
          {ok ? (
            <Button color="primary" onClick={upload} disabled={ctx.import_ongoing}>
              Import {row_data.length} {as_title(role_type + 's')}
            </Button>
          ): null}
        </ButtonGroup>
      </div>
    </div>
  )
}

export const Summary = WithContext(withRouter(Summary_))

export {UNKNOWN}
