import React from 'react'
import {Button} from 'reactstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {as_title} from 'reactstrap-toolbox'

import {UNKNOWN} from './Summary'

export const remove_column_key = 'remove-column!'

export function SelectInput () {}
SelectInput.prototype.init = function ({value, choices, stopEditing, colDef}) {
  this.client_col = colDef.headerName === 'client'
  this.select = document.createElement('select')
  Object.entries(choices).forEach(([k, v]) => {
    const option = document.createElement('option')
    option.value = k
    if (this.client_col) {
      option.innerText = `${v.name} (${v.email})`
    } else {
      option.innerText = v
    }
    this.select.appendChild(option)
  })
  this.select.className = 'custom-cell-select'
  if (value && choices[value.toLowerCase()]) {
    this.select.value = value.toLowerCase()
  } else {
    this.select.value = Object.keys(choices)[0]
  }
  this.select.addEventListener('change', () => stopEditing())
}
SelectInput.prototype.getGui = function () {return this.select}
SelectInput.prototype.afterGuiAttached = function () {this.select.focus()}
SelectInput.prototype.focusIn = function () {this.select.focus()}
SelectInput.prototype.getValue = function () {
  return {v: this.select.value, error: null}
}
SelectInput.prototype.destroy = () => null
SelectInput.prototype.isPopup = function () {return this.client_col}

export const agColumnHeader = ({column, displayName, agGridReact}) => {
  const field = column.colDef.field
  if (field === 'remove') {
    return null
  }
  const grid_props = agGridReact.props
  const remove_column = () => grid_props.change_column(field, remove_column_key)
  return (
    <div>
      <Button color="link" className="remove-column-label" onClick={remove_column}>
        <FontAwesomeIcon icon="times" /> Delete column
      </Button>
      <div className={displayName === UNKNOWN ? 'invalid': null} title={column.userProvidedColDef.__ob__}>
        <select value={displayName}
                className="custom-select"
                disabled={!column.colDef.editable}
                onChange={e => grid_props.change_column(field, e.target.value)}>
          {displayName === UNKNOWN ? <option value={UNKNOWN}>{UNKNOWN}</option>: null}
          {Object.keys(grid_props.fields).map(name => (
            <option key={name} value={name}>{grid_props.fields[name].label || as_title(name)}</option>
          ))}
        </select>
      </div>
    </div>
  )
}

export const default_columns = [
  {
    field: 'remove',
    width: 30,
    minWidth: 30,
    cellClass: 'remove-cell',
    editable: false,
    cellRendererFramework: ({agGridReact, rowIndex}) => (
      <div title="Remove Row">
        <Button size="sm" color="danger" onClick={() => agGridReact.props.remove_row(rowIndex)}>
          <FontAwesomeIcon icon="times"/>
        </Button>
      </div>
    ),
  },
]

export const valueGetter = ({column, data}) => {
  const value = data[column.colDef.field]
  return typeof(value) === 'object' ? value.v : value
}

export const cellRendererFramework = ({value, valueFormatted, data, colDef, agGridReact, rowIndex}) => {
  const grid_props = agGridReact.props
  let title = "You cannot change a Student's Paying Client"
  if (grid_props.cell_editable(colDef, rowIndex)) {
    title = data[colDef.field].error
  }
  return <div title={title}>{valueFormatted || value}</div>
}

export function get_column_lookup (column_defs) {
  if (!column_defs) {
    return {}
  }
  return Object.assign(
    ...column_defs.map(c => ({
      [c.field]: {
        heading: c.headerName,
        params: c.cellEditorParams,
      },
    }))
  )
}
