import React from 'react'
import {Progress} from 'reactstrap'
import Dropzone from 'react-dropzone'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {DetailedError, WithContext} from 'reactstrap-toolbox'
import * as Sentry from '@sentry/browser'

const failed_icon = 'minus-circle'
const accepted_file_types = ['.csv', '.tsv', '.xls', '.xlsx']
const max_size = 10 * 1024 * 1024
const url = '/api/upload/'


class Drop extends React.Component {
  state = {}
  uploads = []

  upload_file = file => {
    const formData = new FormData()
    formData.append('file', file)
    const xhr = new XMLHttpRequest()
    xhr.open('POST', url, true)
    const failed = (event, reason) => {
      const e = DetailedError(
        reason || event.message || 'Error while uploading dropped file',
        {error: event.toString(), status: xhr.status, response: xhr.responseText, url}
      )
      this.props.ctx.setError(e)
    }
    xhr.onload = event => {
      if (xhr.status === 200) {
        this.setState({upload: {progress: 100, icon: 'check', file}})
        const data = JSON.parse(xhr.response)
        this.props.uploaded(data)
      } else if (xhr.status === 400) {
        const data = JSON.parse(xhr.response)
        this.setState({upload: {icon: failed_icon, message: data.message || 'A problem occurred', file}})
      } else {
        failed(event)
      }
    }
    xhr.onerror = failed
    xhr.upload.onprogress = e => {
      if (e.lengthComputable) {
        this.setState({upload: {
          // 90 not 100 so progress only completes when the request finish
          progress: e.loaded / e.total * 90,
          file,
        }})
      }
    }
    xhr.send(formData)
    this.uploads.push(xhr)
  }

  on_drop = (accepted_files, refused_files) => {
    if (accepted_files.length) {
      const file = accepted_files[0]
      this.setState({upload: {file}})
      this.upload_file(file)
    } else if (refused_files.length) {
      const file = refused_files[0]
      this.setState({upload: {file, icon: failed_icon, message: 'Not a valid file'}})
      Sentry.configureScope(scope => {
        scope.setExtra('extra', {file})
        Sentry.captureMessage('File type not supported: ' + file.name, 'warning')
      })
    }
  }

  componentWillUnmount () {
    for (let xhr of this.uploads) {
      xhr.abort()
    }
  }

  content = ({getRootProps, getInputProps}) => (
    <div className="dropzone text-center" {...getRootProps()}>
      <input {...getInputProps()} />
      <FontAwesomeIcon icon="file-upload" size="3x"/>
      <br/><br/>
      <p>Drop an Excel or CSV file here, or click to select a file to upload.</p>
      <p className="small">
        <b>xlsx</b> and <b>csv</b> files are supported.
        <br/>
        You'll be able to choose which columns match which fields in the next step.
      </p>
      <div className="previews">
        {this.state.upload && (
          <div className="file-preview">
            <div>
              {this.state.upload.file ? this.state.upload.file.name : ''}
            </div>
            <div>
              {this.state.upload.progress && <Progress value={this.state.upload.progress} className="mt-1"/>}
            </div>
            {this.state.upload.icon && <FontAwesomeIcon icon={this.state.upload.icon} className="mt-1"/>}
            {this.state.upload.message && <div className="mt-1">{this.state.upload.message}</div>}
          </div>
        )}
      </div>
    </div>
  )

  render () {
    return (
      <Dropzone onDrop={this.on_drop} accept={accepted_file_types} maxSize={max_size} multiple={false}>
        {this.content}
      </Dropzone>
    )
  }
}
export default WithContext(Drop)
