import React from 'react'
import {Route, Switch, withRouter} from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import {library as FaLibrary} from '@fortawesome/fontawesome-svg-core'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {Alert} from 'reactstrap'
import {GlobalContext, Error, NotFound} from 'reactstrap-toolbox'

import Auth from './Auth'
import Navbar from './Navbar'
import ErrorSummary from './ErrorSummary'
import Start from './start/Start'
import Select from './start/Select'
import Sheet from './sheets/Main'
import Websocket from './websocket'

// TODO replace with specific icons
FaLibrary.add(far, fas, fab)

const Main = ({app_state}) => {
  if (app_state.error) {
    return <Error className="container box" error={app_state.error}/>
  } else {
    return (
      <Switch>
        <Route exact path="/" component={Start}/>
        <Route exact path="/auth/:token/" component={Auth}/>
        <Route exact path="/select/:role_type(tutor|client|student)/" component={Select}/>
        <Route exact path="/sheet/:role_type(tutor|client|student)/:key([0-9a-f]{15,})/" component={Sheet}/>
        <Route exact path="/import-error/:key([0-9a-f]{15,})/" component={ErrorSummary}/>
        <Route component={() => <NotFound className="container box"/>}/>
      </Switch>
    )
  }
}

let user = localStorage.user ? JSON.parse(localStorage.user) : null

class App extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      error: null,
      user,
      conn_status: null,
      leave_warning: null,
      import_ongoing: false,
    }
  }

  componentDidMount () {
    this.worker = new Websocket(s => this.setState(s), u => this.setUser(u), p => this.props.history.push(p))
    if (user) {
      this.worker.connect()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location && this.state.error) {
      this.setState({error: null})
    }
  }

  componentDidCatch (error, info) {
    Sentry.configureScope(scope => scope.setExtra('extra', info))
    Sentry.configureScope(scope => {
      scope.setExtra('error', error)
      scope.setExtra('info', info)
      scope.setTag('js', 'componentDidCatch')
      user && scope.setUser({id: user.r_id, username: user.nm})
    })
    this.setState({error})
  }

  setUser = user => {
    if (user) {
      this.worker.connect()
      localStorage.user = JSON.stringify(user)
    } else {
      localStorage.removeItem('user')
    }
    this.setState({user})
  }

  setError = error => {
    console.warn('setting error:', error)
    Sentry.configureScope(scope => {
      scope.setExtra('error', error)
      scope.setTag('js', 'setError')
      user && scope.setUser({id: user.r_id, username: user.nm, branch_id: user.br_id, branch_name: user.br_nm})
    })
    Sentry.captureException(error)
    this.setState({error})
  }

  render () {
    const ctx = {
      setUser: user => this.setUser(user),
      setError: error => this.setError(error),
      setLeave: leave_warning => this.setState({leave_warning}),
      user: this.state.user,
      import_ongoing: this.state.import_ongoing,
    }
    return (
      <GlobalContext.Provider value={ctx}>
        <Navbar app_state={this.state} location={this.props.location}/>
        {this.state.import_ongoing ? (
          <Alert color="danger" className="container">
            <b>Upload Ongoing:</b> You will not be able to import more data until the import currently in progress
            finishes.
          </Alert>
        ) : null}
        <main id="main">
          <Main app_state={this.state}/>
        </main>
      </GlobalContext.Provider>
    )
  }
}

export default withRouter(App)
