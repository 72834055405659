import React from 'react'
import {Loading, WithContext, request} from 'reactstrap-toolbox'
import {withRouter} from 'react-router-dom'

class Auth extends React.Component {
  state = {complete: false}

  async componentDidMount () {
    let r
    try {
      r = await request('GET', `/api/auth/${this.props.match.params.token}/`)
    } catch (e) {
      this.props.ctx.setError(e)
      return
    }
    this.props.ctx.setUser(r.data)
    this.props.history.push('/')
  }

  render = () => <Loading>Authenticating...</Loading>
}

export default WithContext(withRouter(Auth))
