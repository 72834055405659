import React from 'react'
import {Link} from 'react-router-dom'
import {Button} from 'reactstrap'
import {WithContext} from 'reactstrap-toolbox'

export default WithContext(({ctx}) => (
  <div className="container box mt-3">
    {ctx.user ? (
      <div className="text-center">
        <p>Please choose which type of role you'd like to upload:</p>
        <div className="row">
          <div className="role-button">
            <Button size="lg" tag={Link} to="/select/tutor/" color="primary">
              Upload Tutors
            </Button>
            <p>
              <b>Tutors</b> are the client-facing side of your company. Tutors apply to open jobs, create and
              edit lessons, write reports, and mark jobs as finished.&nbsp;
              <a href="https://tutorcruncher.com/crm/help/tutor/" target="_blank" rel="noopener noreferrer">
                Learn more
              </a>.
            </p>
          </div>
          <div className="role-button">
            <Button size="lg" tag={Link} to="/select/client/" color="primary">
              Upload Clients
            </Button>
            <p>
              <b>Clients</b> receive and pay invoices on charges accumulated from lessons and ad hoc charges.
              They are often parents of students, or a school.&nbsp;
              <a href="https://tutorcruncher.com/crm/help/client/" target="_blank" rel="noopener noreferrer">
                Learn more
              </a>.
            </p>
          </div>
          <div className="role-button">
            <Button size="lg" tag={Link} to="/select/student/" color="primary">
              Upload Students
            </Button>
            <p>
              <b>Students</b> are the ones who receive the tutoring. They are generally the children of a client, and
              are added to the lessons and jobs.&nbsp;
              <a href="https://tutorcruncher.com/crm/help/student/" target="_blank" rel="noopener noreferrer">
                Learn more
              </a>.
            </p>
          </div>
        </div>
      </div>
    ) : (
      <div>
        <h1>Error</h1>
        <p>
          Not authenticated, you should only arrive here when redirect from TutorCruncher's import page.
        </p>
      </div>
    )}
  </div>
))
