import moment from 'moment'
import isEmail from 'validator/lib/isEmail'
import {as_title} from 'reactstrap-toolbox'


export default function validate (value, column, duplicate_emails, invalid_emails, paying_client) {
  let error = null
  if (column && column.heading === 'client' && paying_client) {
    value = paying_client
  } else if (column && column.params) {
    if (column.params.choices) {
      [value, error] = validate_choices(value, column)
    }
    if (value) {
      if (column.params.validate === 'email') {
        if (invalid_emails && invalid_emails.has(value)) {
          error = 'Invalid email address'
        } else if (!isEmail(value)) {
          error = 'Invalid email address'
        } else if (duplicate_emails.has(value)) {
          error = 'Duplicate email address'
        }
        duplicate_emails.add(value)
      } else if (column.params.validate === 'date') {
        [value, error] = validate_date(value, column)
      } else if (column.params.validate === 'integer') {
        [value, error] = validate_integer(value, column)
      } else if (column.params.validate === 'stars') {
        [value, error] = validate_stars(value, column)
      }
    } else if (column.params.required) {
      error = as_title(column.heading) + ' is required'
    }
  }
  return {v: value, error}
}

const simplify = v => v.toLowerCase().trim().replace(/[_ \n]/, '-')


function validate_choices (value, column) {
  if (!value) {
    return [value, null]
  }
  const lower_value = value.toLowerCase()
  if (column.params.choices[lower_value]) {
    return [lower_value, null]
  }
  const simple_value = simplify(value)
  if (column.heading === 'client') {
    for (let [k, v] of Object.entries(column.params.choices)) {
      if (simple_value === simplify(v.email) || simple_value === simplify(v.name)) {
        return [k, null]
      }
    }
  } else {
    for (let [k, v] of Object.entries(column.params.choices)) {
      if (simple_value === simplify(v)) {
        return [k, null]
      }
    }
  }
  return [value, 'Not a permitted value for ' + as_title(column.heading)]
}

function validate_date (value, column) {
  for (let fmt of date_parse_formats) {
    const d = moment(value, fmt, true)
    if (d.isValid()) {
      return [d.format('YYYY-MM-DD'), null]
    }
  }
  return [value, as_title(column.heading) + ' not a valid date']
}

function validate_integer (value, column) {
  const no = parseInt(value)
  if (isNaN(no)) {
    return [value, as_title(column.heading) + ': not a number']
  } else {
    return [Math.round(no) + '', null]
  }
}

function validate_stars (value, column) {
  const no = parseFloat(value)
  if (isNaN(no)) {
    return [value, as_title(column.heading) + ': not a number']
  }
  if (no < 0) {
    return [value, as_title(column.heading) + ': number must be greater than or equal to 0']
  } else if (no > column.params.max_stars) {
    return [value, as_title(column.heading) + `: number must be less than or equal to ${column.params.max_stars}`]
  }
  return [Math.round(no) + '', null]
}

let date_parse_formats

async function update_data_formats () {
  if (!localStorage.country_code) {
    const r = await fetch(`${window.location.origin}/api/country/`)
    if (r.status === 200) {
      const data = await r.json()
      localStorage.country_code = data.country_code
    }
  }
  if (localStorage.country_code === 'US') {
    date_parse_formats = [
      'YYYY-MM-DD',
      'YYYY/MM/DD',
      'MM-DD-YYYY',
      'MM/DD/YYYY',
      'MM-DD-YY',
      'MM/DD/YY',
    ]
    moment.locale('en-us')
  } else {
    date_parse_formats = [
      'YYYY-MM-DD',
      'YYYY/MM/DD',
      'DD-MM-YYYY',
      'DD/MM/YYYY',
      'DD-MM-YY',
      'DD/MM/YY',
    ]
    moment.locale('en-gb')
  }
}
update_data_formats()
