import React from 'react'
import {withRouter} from 'react-router'
import {as_title, WithContext, Error} from 'reactstrap-toolbox'
import {Link} from 'react-router-dom'
import {Row, Col} from 'reactstrap'

import Drop from './Drop'

class Start extends React.Component {
  state = {}

  uploaded = data => {
    if (data.choices) {
      this.setState({choices: data.choices})
    } else {
      this.props.history.push(`/sheet/${this.props.match.params.role_type}/${data.sheet_key}/`)
    }
  }

  choices = () => (
    <div className="container box mt-3">
      <Row className="justify-content-center">
        <Col md="6" className="align-self-center">
          <p>Please select which sheet to upload:</p>
          <ul>
            {this.state.choices.map(c => (
              <li key={c.key}>
                <Link to={`/sheet/${this.props.match.params.role_type}/${c.key}/`}>{c.name}</Link>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </div>
  )

  render () {
    if (!this.props.ctx.user) {
      return <Error className="container box" error={{message: 'Not autenticated'}}/>
    }
    if (this.state.choices) {
      return this.choices()
    }
    return (
      <div className="container box mt-3">
        <h1>{as_title(this.props.match.params.role_type)} Upload</h1>
        <Drop uploaded={this.uploaded}/>
      </div>
    )
  }
}
export default WithContext(withRouter(Start))
