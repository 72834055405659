import React from 'react'
import {withRouter} from 'react-router'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  Collapse,
  Navbar as NavbarBootstrap,
  NavbarBrand,
} from 'reactstrap'


export default withRouter(({app_state}) => {
  return (
    <NavbarBootstrap color="dark" dark fixed="top" expand="md">
      <NavbarBrand href="https://secure.tutorcruncher.com/" className="text-light">
        <FontAwesomeIcon icon="arrow-left" className="mr-1"/>
        Back to TutorCruncher
      </NavbarBrand>
      {app_state.user && (
        <Collapse navbar>
          <div className="text-light title">
            <h1 className="text-light text-center">TutorCruncher User Import</h1>
          </div>
          <div className="text-light">
            {app_state.user.br_nm}, {app_state.user.nm}
          </div>
        </Collapse>
      )}
    </NavbarBootstrap>
  )
})
