import React from 'react'
import {AgGridReact} from 'ag-grid-react'
import {as_title, Loading, request, WithContext, choice_modal, message_toast} from 'reactstrap-toolbox'
import {Summary, UNKNOWN} from './Summary'
import {
  remove_column_key,
  agColumnHeader,
  default_columns,
  valueGetter,
  cellRendererFramework,
  get_column_lookup,
  SelectInput,
} from './utils'
import validate from './validation'

class Sheet extends React.Component {
  state = {editable: true, changed: false}
  api = null
  sheet_ref = React.createRef()

  componentDidMount () {
    window.addEventListener('resize', this.resize_grid)
    this.reset()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.resize_grid)
    window.removeEventListener('beforeunload', this.before_unload)
    this.props.ctx.setLeave(null)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    this.resize_grid()
  }

  reset = async () => {
    let r
    const p = this.props.match.params
    try {
      r = await request('GET', `/api/sheet/${p.role_type}/${p.key}/`)
    } catch (e) {
      this.props.ctx.setError(e)
      return
    }
    this.setState({
      fields: r.data.fields,
      required_fields: Object.entries(r.data.fields).filter(([k, v]) => v.required).map(([k, v]) => k),
    })
    this.existing_users = r.data.existing_users
    const new_column_defs = Object.entries(r.data.sheet_data.headings).map(
      ([k, c]) => ({
        field: k,
        headerName: c.suggestion || UNKNOWN,
        valueGetter,
        __ob__: c.heading,
        cellRendererFramework,
      })
    )

    this.update_values({
      column_defs: [...default_columns, ...new_column_defs],
      row_data: r.data.sheet_data.data,
    })
  }

  changed = () => {
    window.addEventListener('beforeunload', this.before_unload)
    this.props.ctx.setLeave("If you leave this page you will lose all changes you've made!")
    if (!this.state.changed) {
      this.setState({changed: true})
    }
  }

  update_values = (d, refresh) => {
    let column_defs = d.column_defs || this.state.column_defs
    let row_data = d.row_data || this.state.row_data

    column_defs = column_defs.map(v => {
      const field_extra = this.state.fields[v.headerName]
      if (field_extra && field_extra.choices) {
        return Object.assign({}, v, {
          cellEditor: 'SelectInput',
          editable: ({colDef, node}) => this.cell_editable(colDef, parseInt(node.id)),
          cellEditorParams: field_extra,
          valueFormatter: ({value}) => {
            const name = field_extra.choices[value]
            if (name && v.headerName === 'client') {
              return `${name.name} (${name.email})`
            } else {
              return name || value
            }
          },
        })
      } else {
        return Object.assign({}, v, {
          cellEditor: null,
          cellEditorParams: field_extra,
          valueFormatter: null,
          valueParser: ({newValue, node, colDef}) => (
            {v: newValue || null, error: this.state.row_data[node.rowIndex][colDef.field].error || null}
          ),
        })
      }
    })

    const column_lookup = get_column_lookup(column_defs)
    const duplicate_emails = new Set()
    const email_col = Object.entries(column_lookup).find(kv => kv[1].heading === 'email')
    row_data = row_data.map(row => {
      let paying_client = null
      if (email_col) {
        paying_client = this.existing_users[row[email_col[0]].v]
      }
      return Object.assign(
        ...Object.entries(row).map(([k, v]) => (
          {[k]: validate(v.v, column_lookup[k], duplicate_emails, this.invalid_emails, paying_client)}
        ))
      )
    })
    if (email_col) {
      this.existing_rows = Object.assign({},
        ...row_data
          .map(r => this.existing_users[r[email_col[0]].v && r[email_col[0]].v.toLowerCase()])
          .map((b, i) => b ? {[i]: b}  : null)
          .filter(r => r)
      )
    } else {
      this.existing_rows = {}
    }
    this.setState({column_defs, row_data}, () => {
      this.resize_grid()
      refresh && this.api.refreshCells()
    })
  }

  // client cannot be edited on an existing student
  cell_editable  = (colDef, rowIndex) => colDef.headerName !== 'client' || !this.existing_rows.hasOwnProperty(rowIndex)

  resize_grid = () => {
    const sheet = this.sheet_ref.current
    if (this.api && sheet) {
      const width = Math.max(sheet.clientWidth - 18, this.state.column_defs.length * 120)
      this.api.gridPanel.columnController.sizeColumnsToFit(width)
      sheet.style.height = `calc(100vh - ${sheet.offsetTop}px)`
    }
  }

  before_unload = (e) => {
    e.preventDefault()
    e.returnValue = true
  }

  on_grid_ready = ({api}) => {
    this.api = api
    this.resize_grid()
  }

  change_column = (key, value) => {
    let column_defs = [...this.state.column_defs]
    if (value === remove_column_key) {
      column_defs = column_defs.filter(c => c.field !== key)
    } else {
      column_defs = column_defs.map(c => (
        Object.assign({}, c, {headerName: c.field === key ? value : c.headerName})
      ))
    }
    this.update_values({column_defs})
    this.changed()
  }

  remove_row = row_index => {
    const row_data = this.state.row_data.filter((r, i) => i !== row_index)
    this.update_values({row_data})
    this.changed()
  }

  cell_changed = ({oldValue, newValue, rowIndex, data}) => {
    if ((oldValue || null) !== (newValue || null)) {
      const row_data = this.state.row_data.map((r, i) => i === rowIndex ? data : r)
      this.update_values({row_data}, true)
      this.changed()
      this.api.ensureIndexVisible(rowIndex, 'middle')
    }
  }

  default_columns_defs = () => ({
    rowDrag: false,
    resizable: true,
    lockPosition: true,
    cellClassRules: {
      user_exists: ({rowIndex}) => this.existing_rows.hasOwnProperty(rowIndex),
      invalid: ({data, colDef}) => {
        const value = data[colDef.field]
        return typeof(value) === 'object' ? value.error : false
      },
      readonly: ({colDef, rowIndex}) => !this.cell_editable(colDef, rowIndex),
    },
    editable: this.state.editable,
  })

  upload = async () => {
    this.setState({editable: false})
    const role_type = this.props.match.params.role_type
    const role_name = as_title(role_type)
    const count = this.state.row_data.length
    const message = (
      <div>
        <p>Are you sure you want to upload {count} {role_name}s?</p>
        <p className="font-weight-bold">This cannot be undone except by manually deleting each new {role_name}.</p>
      </div>
    )
    const choices = [
      {
        answer: false,
        text: 'Cancel',
      },
      {
        answer: 'no_emails',
        colour: 'primary',
        text: 'Upload without Emails',
      },
      {
        answer: 'send_emails',
        colour: 'success',
        text: 'Upload and Send Emails',
      },
    ]
    const ans = await choice_modal(message, choices)
    if (!ans) {
      this.setState({editable: true})
      return
    }
    const send_data = {
      columns: Object.assign(
        ...this.state.column_defs
          .filter(c => c.headerName)
          .map(c => ({[c.field]: c.headerName}))
      ),
      rows: this.state.row_data.map(r => (
        Object.assign(...Object.entries(r).map(([k, v]) => ({[k]: v.v})))
      )),
      send_emails: ans === 'send_emails',
    }
    const column_defs = [...this.state.column_defs]
    this.setState({column_defs: null})
    let r
    try {
      r = await request('POST', `/api/sheet/${role_type}/import/`, {send_data, expected_status: [200, 400]})
    } catch (e) {
      this.props.ctx.setError(e)
      return
    }
    if (r.status === 200) {
      message_toast({title: 'Success', message: `${count} ${role_name}s successfully uploaded.`, icon: 'thumbs-up'})
      this.props.history.push('/')
    } else {
      this.invalid_emails = new Set(r.data.details.invalid)
      this.update_values({column_defs}, true)
      this.setState({editable: true})
    }
  }

  render () {
    if (!this.state.column_defs) {
      return <Loading>Getting Initial data...</Loading>
    }
    return (
      <div>
        <Summary
          row_data={this.state.row_data}
          column_defs={this.state.column_defs}
          required_fields={this.state.required_fields}
          existing_rows={this.existing_rows}
          role_type={this.props.match.params.role_type}
          upload={this.upload}
          changed={this.state.changed}
          reset={this.reset}
        />
        <div id="sheet" ref={this.sheet_ref} className="ag-theme-balham">
          <AgGridReact
            columnDefs={this.state.column_defs}
            rowData={this.state.row_data}
            defaultColDef={this.default_columns_defs()}
            onGridReady={this.on_grid_ready}
            onCellValueChanged={this.cell_changed}
            singleClickEdit={true}
            frameworkComponents={{agColumnHeader}}
            components={{SelectInput}}
            change_column={this.change_column}
            remove_row={this.remove_row}
            fields={this.state.fields}
            change_cell={this.change_cell}
            cell_editable={this.cell_editable}
            headerHeight="57"
            rowHeight="32"
          />
        </div>
      </div>
    )
  }
}

export default WithContext(Sheet)


