import {message_toast} from 'reactstrap-toolbox'

const offline = 0
const connecting = 1
const online = 2


export default class Websocket {
  constructor (set_state, set_user, history_push) {
    this._app_set_state = set_state
    this._app_set_user = set_user
    this._history_push = history_push
    this._state = offline  // see above for options
    this._disconnects = 0
    this._socket = null
  }

  connect = async () => {
    if (this._state !== offline) {
      return
    }
    this._state = connecting
    const ws_origin = window.location.origin.replace('http', 'ws').replace(':3000', ':8000')

    try {
      this._socket = new WebSocket(ws_origin + '/api/ws/')
    } catch (error) {
      console.error('ws connection error', error)
      this._state = offline
      return
    }

    setTimeout(() => this._socket.close(), 40000)

    this._socket.onopen = () => {
      console.debug('websocket open')
      this._state = online
      setTimeout(() => {
        if (this._state === online) {
          this._disconnects = 0
        }
      }, 500)
    }

    this._socket.onclose = async e => {
      this._state = offline
      const reconnect_in = Math.min(10000, (2 ** this._disconnects - 1) * 500)
      this._disconnects += 1
      if (e.code === 4403) {
        console.debug('websocket closed with 4403, not authorised')
        this._app_set_user(null)
      } else {
        console.debug(`websocket closed, reconnecting in ${reconnect_in}ms`, e)
        setTimeout(this.connect, reconnect_in)
      }
    }

    this._socket.onerror = e => {
      console.debug('websocket error:', e)
    }

    this._socket.onmessage = this._on_message
  }

  _on_message = event => {
    const data = JSON.parse(event.data)
    console.debug('ws message:', data)
    this._app_set_state({import_ongoing: data.import_ongoing})
    if (!data.import_ongoing) {
      let title = 'Import Complete'
      let message = 'Import complete, you can now import more users'
      let icon = 'check'
      if (data.import_error) {
        title = 'Import Errors'
        message = 'Errors occurred during import'
        icon = 'times'
        this._history_push(`/import-error/${data.import_error}/`)
      }
      message_toast({title, message, icon})
    }
  }
}
