import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter as Router} from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import './styles/main.scss'
import App from './App'

window.Sentry = Sentry

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_COMMIT,
    attachStacktrace: true,
  })
}
ReactDOM.render(<Router><App/></Router>, document.getElementById('root'))
